<template>
  <div class="profileformmed">
    <v-form ref="form">
      <v-container fluid>
        <v-row>
          <span class="text-subtitle-1"> Bezeichnung </span>
          <v-col cols="12">
            <v-text-field
              id="facilityName"
              v-model="facility.name"
              label="Name*"
              data-cy="facilityName"
              hide-details="auto"
              :readonly="readonly"
              variant="outlined"
              :error-messages="nameErrors"
              @blur="v$.facility.name.$touch()"
            />
          </v-col>
          <v-col cols="12">
            <v-text-field
              id="facilityShortName"
              v-model="facility.short_name"
              label="Abkürzung*"
              data-cy="facilityShortName"
              hide-details="auto"
              :readonly="readonly"
              variant="outlined"
              :error-messages="shortNameErrors"
              @blur="v$.facility.short_name.$touch()"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-switch
              v-model="facility.show_in_hierarchy"
              color="primary"
              hide-details="auto"
              label="Einrichtung in Breadcrumbs anzeigen"
            ></v-switch>
          </v-col>
          <v-col
            v-if="facility.parent_facility"
            cols="12"
            data-cy="speciality"
          >
            <select-speciality
              v-model="facility.selectSpecialities"
              label="Fachbereiche der Klinik"
              hide-details="auto"
              multiple
              first-items-name="vorhandene Weiterbildungsermächtigungen"
              variant="outlined"
            />
          </v-col>
        </v-row>
        <v-row>
          <span class="text-subtitle-1 mt-3"> Adresse </span>
          <v-col
            v-if="
              facility.parent_facility &&
              facility.parent_facility.id &&
              hasRole(['ADMINISTRATIVE_STAFF'])
            "
            cols="12"
          >
            <select-facility
              v-model="facility.parent_facility.id"
              label="übergeordnete Einrichtung"
            />
          </v-col>
        </v-row>
        <app-address
          v-model="facility.address"
          :readonly="readonly"
        />
        <v-row> </v-row>
      </v-container>
    </v-form>
  </div>
</template>

<script>
import { required } from '@vuelidate/validators'
import { useVuelidate } from '@vuelidate/core'
export default {
  props: {
    facilityId: {
      type: String,
      default: null,
    },
    readonly: {
      type: Boolean,
    },
    newParentFacilityId: {
      type: String,
      default: null,
    },
  },
  setup() {
    return { v$: useVuelidate() }
  },
  data() {
    return {
      facility: {},
    }
  },
  validations() {
    return {
      facility: {
        name: { required },
        short_name: { required },
      },
    }
  },
  computed: {
    nameErrors() {
      const errors = []
      if (!this.v$.facility.name || !this.v$.facility.name.$dirty) {
        return errors
      }
      this.v$.facility.name.required.$invalid && errors.push('Dies ist ein Pflichtfeld')
      return errors
    },
    shortNameErrors() {
      const errors = []
      if (!this.v$.facility.short_name || !this.v$.facility.short_name.$dirty) {
        return errors
      }
      this.v$.facility.short_name.required.$invalid && errors.push('Dies ist ein Pflichtfeld')
      return errors
    },
  },
  async mounted() {
    if (this.facilityId && this.facilityId !== '0') {
      this.facility = await this.$cms.request(
        this.$readItem('facility', this.facilityId, {
          fields: [
            'id',
            'name',
            'short_name',
            'show_in_hierarchy',
            'address.id',
            'address.street',
            'address.street_number',
            'address.addition',
            'address.zip',
            'address.city',
            'address.state_code',
            'address.country_code',
            'specialities.id',
            'specialities.speciality_id',
            'parent_facility.id',
            'parent_facility.address.id',
            'parent_facility.address.street',
            'parent_facility.address.street_number',
            'parent_facility.address.addition',
            'parent_facility.address.zip',
            'parent_facility.address.city',
            'parent_facility.address.state_code',
            'parent_facility.address.country_code',
          ],
        })
      )
      this.facility.selectSpecialities = [
        ...new Set(this.facility.specialities.map((speciality) => speciality.speciality_id)),
      ]
    } else if (this.newParentFacilityId) {
      this.facility.parent_facility = { id: this.newParentFacilityId }
      this.setParentFacility()
    }
    this.$watch('facility.parent_facility.id', this.setParentFacility)
  },
  methods: {
    async setParentFacility() {
      if (this.facility.parent_facility && this.facility.parent_facility.id) {
        await this.$cms
          .request(
            this.$readItem('facility', this.facility.parent_facility.id, {
              fields: [
                'id',
                'address.id',
                'address.street',
                'address.street_number',
                'address.addition',
                'address.zip',
                'address.city',
                'address.state_code',
                'address.country_code',
                'specialities.id',
                'specialities.speciality_id',
              ],
            })
          )
          .then((parentFacility) => {
            this.facility.address = parentFacility.address
            this.facility.selectSpecialities = [
              ...new Set(parentFacility.specialities.map((speciality) => speciality.speciality_id)),
            ]
          })
      }
    },
    async submit() {
      this.v$.$touch()
      if (!this.v$.$invalid) {
        const facility = {
          name: this.facility.name,
          short_name: this.facility.short_name || null,
          show_in_hierarchy: this.facility.show_in_hierarchy,
        }
        if (this.facility.address) {
          facility.address = {
            street: this.facility.address.street,
            street_number: this.facility.address.street_number,
            addition: this.facility.address.addition,
            zip: this.facility.address.zip,
            city: this.facility.address.city,
            state_code: this.facility.address.state_code,
            country_code: this.facility.address.country_code,
          }
        }
        if (this.facility.selectSpecialities) {
          facility.specialities = this.facility.selectSpecialities.map((speciality) => {
            return { speciality_id: speciality }
          })
        }
        if (
          this.facility.parent_facility &&
          this.facility.parent_facility.id &&
          this.hasRole(['ADMINISTRATIVE_STAFF'])
        ) {
          facility.parent_facility = this.facility.parent_facility.id
        }

        const fields = [
          'id',
          'name',
          'short_name',
          'show_in_hierarchy',
          'address.id',
          'address.street',
          'address.street_number',
          'address.addition',
          'address.zip',
          'address.city',
          'address.state_code',
          'address.country_code',
          'specialities.id',
          'specialities.speciality_id',
          'parent_facility.id',
          'parent_facility.name',
          'parent_facility.address.id',
          'parent_facility.address.street',
          'parent_facility.address.street_number',
          'parent_facility.address.addition',
          'parent_facility.address.zip',
          'parent_facility.address.city',
          'parent_facility.address.state_code',
          'parent_facility.address.country_code',
        ]

        if (this.facilityId && this.facilityId !== '0') {
          this.facility = await this.$cms.request(
            this.$updateItem('facility', this.facilityId, facility, { fields })
          )
        } else {
          facility.main_facility = this.currentUser.main_facility.id
          this.facility = await this.$cms.request(
            this.$createItem('facility', facility, { fields })
          )
          const newUserFacility = await this.$cms.request(
            this.$createItem(
              'facility_directus_users',
              { directus_users_id: this.currentUser.id, facility_id: this.facility.id },
              { fields: ['facility_id.id, facility_id.name, type, id'] }
            )
          )
          if (this.currentUser.facilities) {
            this.currentUser.facilities.push(newUserFacility)
          }
        }
        if (this.facility.specialities) {
          this.facility.selectSpecialities = [
            ...new Set(this.facility.specialities.map((speciality) => speciality.speciality_id)),
          ]
        }
        return JSON.parse(JSON.stringify(this.facility))
      }
    },
  },
}
</script>
